import { Mesh } from 'three';
import { defineComponent, watchEffect } from 'vue';

import { simpleMaterial, simplePlaneGeometry } from '@resn/gozer-three';

import { useBounds } from '../Bounds';
import { useThreeOrthoObject } from '~/composables/useThreeOrthoObject';
import { LAYER_FG } from '~/core/constants';

const OrthographicWrapper = defineComponent({
    props: {
        visible: { default: true },
        name: { default: 'OrthographicWrapper' },
    },

    setup(props) {
        const { object } = useThreeOrthoObject(null, { name: props.name });

        const hasDebug = false;

        let meshDebug = null;
        if (hasDebug) {
            meshDebug = new Mesh(simplePlaneGeometry, simpleMaterial('blue', true));
            meshDebug.layers.set(LAYER_FG);
            meshDebug.material.depthTest = false;
            meshDebug.material.opacity = 0.3;
            meshDebug.renderOrder = 10;

            object.add(meshDebug);
        }

        useBounds(({ x, y, width, height }) => {
            object.position.set(x, y, 0);

            if (hasDebug) {
                meshDebug.position.set(x, y, 0);
                meshDebug.scale.set(width, height, 1);
            }
        });

        watchEffect(() => {
            object.visible = props.visible;
        });
    },

    render() {
        if (this.$slots.default) {
            return this.$slots.default();
        }

        return null;
    },
});

export default OrthographicWrapper;
